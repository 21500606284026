import { ArcRotateCamera, Color3, Color4, CreateBox, CreatePlane, Engine, InputBlock, Mesh, NodeMaterial, Scene, StandardMaterial, Texture, TransformNode, Vector3, VideoTexture } from "@babylonjs/core";
import { ChangeEvent, Component } from "react";
import simple_hologram from "../shaders/simple_hologram.json";
import { XRGrid } from "./xrGrid";
import { GUIPlane } from "./guiPlane";
import { TextBlock } from "@babylonjs/gui";

let didMount = false;

type VideoInfoResponse = {
    frameRate: number
    frames: number
    width: number
    height: number
}

interface DepthPlayerSettings {
    aspectRatioWidth: number,
    aspectRatioHeight: number,
    texturePadding: number,
    textureAtlasGridWidth: number,
    textureAtlasGridHeight: number,
    textureDepthDownscaling: number,
    textureNormalDownscaling: number,
    activeTextureWidth: number,
    activeTextureHeight: number,
    textureLayers: {
        textureGridWidth: number,
        textureColorGridHeight: number,
        textureDepthGridHeight: number,
        textureNormalGridHeight: number,
        textureGridOffsetX: number,
        textureGridOffsetY: number
    }[],
    pivotX: number,
    pivotY: number,
    pivotZ: number,
    rotationX: number,
    rotationZ: number,
    scale: number,
    nearScale: number,
    farScale: number,
    extrusion: number,
    depthDistribution: number,
    shadowLength: number,
    shadowStart: number,
    shadowEnd: number,
    alphaBoundsOffsetX: number,
    alphaBoundsOffsetY: number,
    alphaBoundsScaleX: number,
    alphaBoundsScaleY: number
}

type VideoConfig = {
    id: string,
    disabled: boolean,
    sources: {
        url: string,
        above30Fps: boolean,
        above2160p: boolean
    }[],
    preview: string,
    thumbnail: string,
    thumbnailSmall: string,
    name: string,
    category: string,
    creators: string[],
    performers: string[],
    requirements: {
        type: "patreon",
        campaign: string,
        tiers: string[]
    }[],
    // TODO: Decide if groundOffset should be part of settings, meaning that it needs to be set during editing
    groundOffset: number,
    startTime?: number,
    endTime?: number,
    loop: boolean,
    settings: DepthPlayerSettings,
    // TODO: Remove or make it so that all holograms are aligned with the front of the spawn pad
    perspective: {
        spawnPadPosition: { x: number, y: number, z: number },
    }
}

type State = {
    file: File | null
    message: string
    addMessage: string
    convertUrl: string
    isConverting: boolean
    isDone: boolean
    pivotY: number
    rotationX: number
    rotationZ: number
    scale: number
    farScale: number
    extrusion: number
}

export class Automation extends Component<{}, State> {
    constructor(props: {}) {
        super(props);

        this.state = {
            file: null,
            message: "",
            addMessage: "",
            convertUrl: "",
            isConverting: false,
            isDone: false,
            pivotY: 0,
            rotationX: 0,
            rotationZ: 0,
            scale: 1,
            farScale: 1,
            extrusion: 1
        }
    }

    private id: string = "";
    private aspectRatioWidth: number = 1;
    private aspectRatioHeight: number = 0.5625;
    private hologramVideo: VideoTexture | undefined;
    private camera: ArcRotateCamera | undefined;
    private planeParent: TransformNode | undefined;
    private plane: Mesh | undefined;
    private inputBlockFarScale: InputBlock | undefined;
    private inputBlockExtrusion: InputBlock | undefined;

    calculateAspectRatio(width: number, height: number) {
        let a = width;
        let b = height;
        while (b !== 0) {
            let temp = b;
            b = a % b;
            a = temp;
        }
        return (width / a) + ":" + (height / a);
    }

    secondsToHHMMSS(seconds: number) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        return [
            hours.toString().padStart(2, "0"),
            minutes.toString().padStart(2, "0"),
            secs.toString().padStart(2, "0")
        ].join(":");
    }

    waitForQueueToFinish() {
        const apiURL = window.origin + "/api/automation/";
        const status = document.getElementById("status") as HTMLDivElement;

        const interval = setInterval(async () => {
            const response = await fetch(apiURL + "queue").then(res => res.json());
            const isQueueEmpty = response.queue_running.length === 0 && response.queue_pending.length === 0;
            if (isQueueEmpty) {
                clearInterval(interval);
                status.innerText = "Queue is empty";
            }
        }, 5000);
    }

    createPlayer() {
        const canvas = document.getElementById("canvas") as HTMLCanvasElement;
        canvas.addEventListener("wheel", e => e.preventDefault());

        const engine = new Engine(canvas, true);
        const scene = new Scene(engine);
        scene.clearColor = new Color4(0, 0, 0, 0);

        this.camera = new ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2, 3, Vector3.Zero(), scene);
        this.camera.attachControl(canvas, true);
        this.camera.wheelPrecision = 100;
        this.camera.minZ = 0.01;
        this.camera.lowerRadiusLimit = 1;
        this.camera.upperRadiusLimit = 5;
        this.camera.storeState();

        this.hologramVideo = new VideoTexture("video", "", scene, false, true, Texture.BILINEAR_SAMPLINGMODE, { autoPlay: true, loop: true, muted: true });

        const material = new NodeMaterial("material", scene);
        material.parseSerializedObject(simple_hologram);
        material.build();
        const textureBlocks = material.getTextureBlocks();
        for (let textureBlock of textureBlocks) {
            textureBlock.texture = this.hologramVideo;
        }
        material.needDepthPrePass = true;
        material.forceDepthWrite = true;

        this.inputBlockFarScale = material.getInputBlockByPredicate((b) => b.name === "InputFarScale") as InputBlock;
        this.inputBlockFarScale.value = 1;

        this.inputBlockExtrusion = material.getInputBlockByPredicate((b) => b.name === "InputExtrusion") as InputBlock;
        this.inputBlockExtrusion.value = -1;

        const inputBlockExtrusionDirection = material.getInputBlockByPredicate((b) => b.name === "InputExtrusionDirection") as InputBlock;
        inputBlockExtrusionDirection.value = new Vector3(0, 0, 1);

        const grid = new XRGrid("grid", scene, { size: 8, textureRepeatCount: 16 });
        grid.position.y = -1;

        this.planeParent = new TransformNode("planeParent", scene);
        this.planeParent.position.y = -1;

        this.plane = CreatePlane("plane", { width: 1, height: 1, }, scene);
        this.plane.setParent(this.planeParent);
        this.plane.increaseVertices(150);
        this.plane.rotation.x = -Math.PI;
        this.plane.rotation.y = Math.PI;
        this.plane.bakeCurrentTransformIntoVertices();
        this.plane.material = material;
        this.plane.scaling.y = (9 / 16);
        this.plane.scaling.x = -1;
        this.plane.position.y = this.plane.scaling.y * 0.5;
        this.plane.position.z = -this.inputBlockExtrusion.value * 0.5;
        this.plane.renderingGroupId = 1;

        const heightIndicatorMaterial = new StandardMaterial("heightIndicatorMaterial", scene);
        heightIndicatorMaterial.disableLighting = true;
        heightIndicatorMaterial.emissiveColor = new Color3(1, 1, 1);

        const heightIndicator = CreateBox("heightIndicator", {}, scene);
        heightIndicator.scaling = new Vector3(3, 0.01, 0.01);
        heightIndicator.position.y = 0.75;
        heightIndicator.material = heightIndicatorMaterial;

        const guiPlane = new GUIPlane("guiPlane", scene, 512, 128);
        guiPlane.renderingGroupId = 2;
        guiPlane.position.y = 0.75;
        guiPlane.position.x = 1.75;

        const text = new TextBlock();
        text.text = "175cm / 5'9\"";
        text.color = "white";
        text.fontSize = 72;
        guiPlane.texture.addControl(text);

        engine.runRenderLoop(() => {
            scene.render();
        });
    }

    async handleConvert() {
        this.setState({ isConverting: true, isDone: false, addMessage: "" });

        const status = document.getElementById("status") as HTMLDivElement;

        const apiURL = window.origin + "/api/automation/";

        const videoURL = this.state.convertUrl;
        this.id = videoURL.split("/").pop()?.split(".")[0] || "";

        status.innerText = "Converting...";

        const contentConfig = await fetch(apiURL + "content").then(res => res.json());

        const videoInfo = await fetch(apiURL + "video-info?url=" + encodeURIComponent(videoURL)).then(res => res.json()) as VideoInfoResponse;

        if (videoInfo.width > videoInfo.height) {
            this.aspectRatioWidth = 1;
            this.aspectRatioHeight = videoInfo.height / videoInfo.width;
        } else {
            this.aspectRatioWidth = videoInfo.width / videoInfo.height;
            this.aspectRatioHeight = 1;
        }

        if (this.plane) {
            this.plane.scaling.x = -this.aspectRatioWidth;
            this.plane.scaling.y = this.aspectRatioHeight;
            this.plane.position.y = this.plane.scaling.y * 0.5;
        }
        if (this.planeParent) {
            this.planeParent.position.y = -1 - (this.state.pivotY * this.state.scale) * this.aspectRatioHeight;
        }

        const promptURL = apiURL + "prompt?url=" + encodeURIComponent(videoURL) + "&id=" + this.id + "&width=" + videoInfo.width + "&height=" + videoInfo.height;

        let caughtError = false;
        const response = await fetch(promptURL).then(res => res.json()).catch(e => {
            caughtError = true;
            if (e.toString().includes("TimeoutErr")) {
                status.innerText = "Error: Request timed out, please try again later";
            } else {
                status.innerText = "Error: " + e + ", please try again later";
            }
            this.setState({ isConverting: false });
        });

        if (caughtError) {
            return;
        }

        if (response.status === "waiting in queue") {
            this.setState({ isConverting: false });
            status.innerText = "Waiting in queue, unknown time left";
            return;
        }

        const onComplete = () => {
            this.setState({ isConverting: false, isDone: true });
            status.innerText = "Done: https://volxspace.duckdns.org/output/" + this.id + "_Downscaled_Output_00001.mp4";
            if (this.hologramVideo) {
                this.hologramVideo.video.src = "https://volxspace.duckdns.org/output/" + this.id + "_Downscaled_Output_00001.mp4";
                this.hologramVideo.video.play();
            }
            if (this.camera) {
                this.camera.restoreState();
            }
            const existingVideo: VideoConfig | undefined = contentConfig.videos.find((video: VideoConfig) => video.id === this.id);
            if (existingVideo) {
                this.setState({
                    pivotY: existingVideo.settings.pivotY,
                    rotationX: -existingVideo.settings.rotationX,
                    rotationZ: existingVideo.settings.rotationZ,
                    scale: existingVideo.settings.scale,
                    farScale: existingVideo.settings.farScale,
                    extrusion: existingVideo.settings.extrusion
                });
            }
        }

        if (response.status === "done") {
            onComplete();
            return;
        }

        let elapsedTime = 0;
        let intervalStartDate = new Date();

        const interval = setInterval(async () => {
            elapsedTime = Math.floor((new Date().getTime() - intervalStartDate.getTime()) / 1000);
            const elapsedHHMMSS = this.secondsToHHMMSS(elapsedTime);
            const estimatedHHMMSS = this.secondsToHHMMSS(Math.floor(response.estimatedTime));
            status.innerText = "Estimated Progress: " + elapsedHHMMSS + " / " + estimatedHHMMSS;
        }, 1000);

        const interval2 = setInterval(async () => {
            if (elapsedTime >= response.estimatedTime * 0.9) {
                const outputResponse = await fetch(apiURL + "output?id=" + this.id).then(res => res.json());
                if (outputResponse.ok) {
                    clearInterval(interval);
                    clearInterval(interval2);
                    onComplete();
                }
            }
        }, 5000);
    }

    componentDidMount(): void {
        if (didMount) {
            return;
        }

        didMount = true;

        this.createPlayer();

        const status = document.getElementById("status") as HTMLDivElement;

        const apiURL = window.origin + "/api/automation/";

        fetch(apiURL + "time-left").then(res => res.json()).then(response => {
            if (response.timeLeft !== -1 && status) {
                let elapsedTime = 0;
                const interval = setInterval(async () => {
                    elapsedTime++;
                    const elapsedHHMMSS = this.secondsToHHMMSS(elapsedTime);
                    const estimatedHHMMSS = this.secondsToHHMMSS(Math.floor(response.timeLeft));
                    status.innerText = "Queue not empty, please wait. Estimated Progress: " + elapsedHHMMSS + " / " + estimatedHHMMSS;
                    if (elapsedTime >= response.timeLeft) {
                        clearInterval(interval);
                        this.waitForQueueToFinish();
                    }
                }, 1000);
            }
        });
    }

    componentDidUpdate(_prevProps: Readonly<{}>, prevState: Readonly<State>) {
        if (this.state.rotationX !== prevState.rotationX && this.planeParent) {
            this.planeParent.rotation.x = this.state.rotationX * Math.PI / 180;
        }
        if (this.state.rotationZ !== prevState.rotationZ && this.plane) {
            this.plane.rotation.z = this.state.rotationZ * Math.PI / 180;
        }
        if (this.state.pivotY !== prevState.pivotY && this.planeParent) {
            this.planeParent.position.y = -1 - (this.state.pivotY * this.state.scale) * this.aspectRatioHeight;
        }
        if (this.state.scale !== prevState.scale && this.planeParent) {
            this.planeParent.position.y = -1 - (this.state.pivotY * this.state.scale) * this.aspectRatioHeight;
            this.planeParent.scaling.setAll(this.state.scale);
        }
        if (this.state.farScale !== prevState.farScale && this.inputBlockFarScale) {
            this.inputBlockFarScale.value = this.state.farScale;
        }
        if (this.state.extrusion !== prevState.extrusion && this.inputBlockExtrusion) {
            this.inputBlockExtrusion.value = -this.state.extrusion;
        }
    }

    handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            this.setState({ file: event.target.files[0] });
        }
    }

    handleUpload = async () => {
        const { file } = this.state;

        if (!file) {
            this.setState({ message: "Please select a file first." });
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        this.setState({ message: "Uploading..." });

        try {
            const response = await fetch("https://volxspace.duckdns.org/upload", {
                method: "POST",
                body: formData,
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || "Upload failed");
            }

            const convertUrl = "https://volxspace.duckdns.org/uploads/" + data.filename;
            this.setState({ message: data.message + ", " + convertUrl, convertUrl });
        } catch (error) {
            if (error instanceof Error) {
                this.setState({ message: "Upload failed: " + error.message });
            }
        }
    }

    handleAddToVolXSpace = async () => {
        this.setState({ addMessage: "Adding video to VolXSpace..." });

        if (!this.id) {
            return;
        }

        const body = {
            "id": this.id,
            "disabled": false,
            "sources": [
                {
                    "url": "https://volxspace.duckdns.org/output/" + this.id + "_Downscaled_Output_00001.mp4",
                    "above30Fps": false,
                    "above2160p": false
                }
            ],
            "preview": "https://volxspace.duckdns.org/output/" + this.id + "_Output_00001_preview.png",
            "thumbnail": "https://volxspace.duckdns.org/output/" + this.id + "_Output_00001_thumbnail.png",
            "thumbnailSmall": "https://volxspace.duckdns.org/output/" + this.id + "_Output_00001_thumbnail.png",
            "name": this.id,
            "category": "any",
            "creators": [],
            "performers": [],
            "requirements": [],
            "groundOffset": 0,
            "loop": false,
            "settings": {
                "aspectRatioWidth": this.aspectRatioWidth,
                "aspectRatioHeight": this.aspectRatioHeight,
                "texturePadding": 8,
                "textureAtlasGridWidth": 15,
                "textureAtlasGridHeight": 16,
                "textureDepthDownscaling": 2,
                "textureNormalDownscaling": 2,
                "activeTextureWidth": 16,
                "activeTextureHeight": 9,
                "textureLayers": [
                    {
                        "textureGridWidth": 15,
                        "textureColorGridHeight": 9,
                        "textureDepthGridHeight": 3,
                        "textureNormalGridHeight": 3,
                        "textureGridOffsetX": 0,
                        "textureGridOffsetY": 0
                    }
                ],
                "pivotX": 0.5,
                "pivotY": this.state.pivotY,
                "pivotZ": -0.5,
                "rotationX": -this.state.rotationX,
                "rotationZ": this.state.rotationZ,
                "scale": this.state.scale,
                "nearScale": 1,
                "farScale": this.state.farScale,
                "farOffsetY": 0,
                "extrusion": this.state.extrusion,
                "depthDistribution": 0,
                "shadowLength": 1,
                "shadowStart": 0,
                "shadowEnd": 1,
                "alphaBoundsOffsetX": 0.01,
                "alphaBoundsOffsetY": 0.01,
                "alphaBoundsScaleX": 0.98,
                "alphaBoundsScaleY": 0.98
            },
            "perspective": {
                "spawnPadPosition": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                }
            }
        }

        const response = await fetch("https://volxspace.duckdns.org/add_video_to_volxspace", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }).then(res => res.json());

        this.setState({ addMessage: response.message });
    }

    handleRemoveFromVolXSpace = async () => {
        this.setState({ addMessage: "Removing video from VolXSpace..." });

        if (!this.id) {
            return;
        }

        const response = await fetch("https://volxspace.duckdns.org/remove_video_from_volxspace", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: this.id })
        }).then(res => res.json());

        this.setState({ addMessage: response.message });
    }

    render() {
        return (
            <div className="container">
                <div>Converter</div>
                <div>
                    <input type="file" onChange={this.handleFileChange.bind(this)} />
                    <button disabled={!this.state.file} onClick={this.handleUpload.bind(this)}>Upload</button>
                    {this.state.message && <p>{this.state.message}</p>}
                </div>
                <input type="text" name="" placeholder="enter custom url..." value={this.state.convertUrl} onChange={e => this.setState({ convertUrl: e.target.value })} />
                <button disabled={this.state.isConverting || !this.state.convertUrl} onClick={this.handleConvert.bind(this)}>Convert</button>
                <div id="status">Status</div>
                <video id="video" controls hidden style={{ width: "100%" }}></video>
                <canvas id="canvas" style={{ width: "100%" }}></canvas>
                <div style={{ width: "100%", maxWidth: "300px", display: "flex", flexDirection: "column" }}>
                    <div>Pivot Y</div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <input type="range" style={{ width: "100%" }} name="" defaultValue="0" min="-1" max="2" step="0.02" onChange={e => this.setState({ pivotY: parseFloat(e.target.value) })} />
                        <span style={{ width: "50px" }}>{this.state.pivotY}</span>
                    </div>
                    <div>Rotation X</div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <input type="range" style={{ width: "100%" }} name="" defaultValue="0" min="-90" max="90" step="5" onChange={e => this.setState({ rotationX: parseFloat(e.target.value) })} />
                        <span style={{ width: "50px" }}>{this.state.rotationX}</span>
                    </div>
                    <div>Rotation Z</div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <input type="range" style={{ width: "100%" }} name="" defaultValue="0" min="-180" max="180" step="5" onChange={e => this.setState({ rotationZ: parseFloat(e.target.value) })} />
                        <span style={{ width: "50px" }}>{this.state.rotationZ}</span>
                    </div>
                    <div>Scale</div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <input type="range" style={{ width: "100%" }} name="" defaultValue="1" min="0" max="5" step="0.1" onChange={e => this.setState({ scale: parseFloat(e.target.value) })} />
                        <span style={{ width: "50px" }}>{this.state.scale}</span>
                    </div>
                    <div>Far Scale</div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <input type="range" style={{ width: "100%" }} name="" defaultValue="1" min="0" max="5" step="0.1" onChange={e => this.setState({ farScale: parseFloat(e.target.value) })} />
                        <span style={{ width: "50px" }}>{this.state.farScale}</span>
                    </div>
                    <div>Extrusion</div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <input type="range" style={{ width: "100%" }} name="" defaultValue="1" min="0" max="3" step="0.1" onChange={e => this.setState({ extrusion: parseFloat(e.target.value) })} />
                        <span style={{ width: "50px" }}>{this.state.extrusion}</span>
                    </div>
                    <div>Publish</div>
                    <div>
                        <button onClick={this.handleAddToVolXSpace.bind(this)} disabled={!this.state.isDone}>Add to VolXSpace</button>
                    </div>
                    <div>
                        <button onClick={this.handleRemoveFromVolXSpace.bind(this)} disabled={!this.state.isDone}>Remove from VolXSpace</button>
                    </div>
                </div>
                {this.state.addMessage && <p>{this.state.addMessage}</p>}
                <span>To view the holograms, please visit: </span><a href="https://volxspace.com/automation/view" target="_blank" rel="noreferrer">https://volxspace.com/automation/view</a>
            </div>
        )
    }
}