import gridShader from "../shaders/grid_shader.json";

import gridSquareTexture from "../textures/grid_square.png";
import { CreatePlane, Mesh, NodeMaterial, Scene, Texture, VertexBuffer, VertexData } from "@babylonjs/core";

export class XRGrid extends Mesh {

    private size: number;
    private _textureRepeatCount: number;
    private _material: NodeMaterial;
    private texture: Texture;

    constructor(name: string, scene: Scene, { size = 1, textureRepeatCount = 1 } = {}) {
        super(name, scene);

        this.size = size;
        this._textureRepeatCount = textureRepeatCount;

        this.texture = new Texture(gridSquareTexture, scene, true, false, Texture.TRILINEAR_SAMPLINGMODE);
        this.texture.uScale = this._textureRepeatCount;
        this.texture.vScale = this._textureRepeatCount;
        this.texture.hasAlpha = true;

        this._material = new NodeMaterial("gridMaterial", scene);
        this._material.parseSerializedObject(gridShader);
        this._material.build();
        this._material.getTextureBlocks()[0].texture = this.texture;
        this._material.backFaceCulling = false;

        let sourceMesh = CreatePlane("Grid", { width: this.size, height: this.size });
        sourceMesh.rotation.x = Math.PI / 2;
        sourceMesh.bakeCurrentTransformIntoVertices();

        var vertexData = new VertexData();
        vertexData.positions = sourceMesh.getVerticesData(VertexBuffer.PositionKind);
        vertexData.indices = sourceMesh.getIndices();
        vertexData.normals = sourceMesh.getVerticesData(VertexBuffer.NormalKind);
        vertexData.uvs = sourceMesh.getVerticesData(VertexBuffer.UVKind);
        vertexData.applyToMesh(this);
        this.makeGeometryUnique();
        sourceMesh.dispose();

        this.material = this._material;
        this.isPickable = false;
    }

    public get textureRepeatCount() {
        return this._textureRepeatCount;
    }

    public set textureRepeatCount(value: number) {
        this._textureRepeatCount = value;
        this.texture.uScale = this._textureRepeatCount;
        this.texture.vScale = this._textureRepeatCount;
    }

    // override setAbsolutePosition(absolutePosition: Vector3): TransformNode {
    //     let result = super.setAbsolutePosition(absolutePosition);
    //     this.updateTextureUV();
    //     return result;
    // }

    // override get position() {
    //     return super.position;
    // }

    // override set position(value: Vector3) {
    //     super.position = value;
    //     this.updateTextureUV();
    // }

    // override get material(): Material {
    //     return this._material;
    // }

    // private override set material(value: Material) {
    //     super.material = value;
    // }

    // private updateTextureUV() {
    //     let xFraction = this.position.x % 1;
    //     let zFraction = this.position.z % 1;

    //     this.texture.uOffset = xFraction * this.textureRepeatCount / this.size;
    //     this.texture.vOffset = zFraction * this.textureRepeatCount / this.size;
    // }
}