import gui_shader from "../shaders/gui_shader.json";
import { AdvancedDynamicTexture } from "@babylonjs/gui";
import { CreatePlane, Mesh, NodeMaterial, Scene, Vector3, VertexBuffer, VertexData } from "@babylonjs/core";

export class GUIPlane extends Mesh {
    private _material: NodeMaterial;
    private _texture: AdvancedDynamicTexture;
    private _widthInPixels: number;
    private _heightInPixels: number;

    private static _defaultRenderingGroupId = 0;

    public readonly pixelScale = 0.001;

    constructor(name: string, scene: Scene, width: number, height: number) {
        super(name, scene);

        this.renderingGroupId = GUIPlane.defaultRenderingGroupId;

        this.isPickable = true;

        let sourceMesh = CreatePlane("plane", { width: width * this.pixelScale, height: height * this.pixelScale });

        var vertexData = new VertexData();
        vertexData.positions = sourceMesh.getVerticesData(VertexBuffer.PositionKind);
        vertexData.indices = sourceMesh.getIndices();
        vertexData.normals = sourceMesh.getVerticesData(VertexBuffer.NormalKind);
        vertexData.uvs = sourceMesh.getVerticesData(VertexBuffer.UVKind);
        vertexData.applyToMesh(this);

        this.makeGeometryUnique();

        sourceMesh.dispose();

        this._texture = AdvancedDynamicTexture.CreateForMesh(this, width, height);

        this._material = new NodeMaterial("planeMaterial", scene);
        this._material.parseSerializedObject(gui_shader);
        this._material.backFaceCulling = false;
        this._material.build();
        this._material.getTextureBlocks()[0].texture = this._texture;

        this._widthInPixels = width;
        this._heightInPixels = height;

        this.material = this._material;
    }

    public static get defaultRenderingGroupId(): number {
        return this._defaultRenderingGroupId;
    }

    public static set defaultRenderingGroupId(value: number) {
        this._defaultRenderingGroupId = value;
    }

    get texture(): AdvancedDynamicTexture {
        return this._texture;
    }

    get width(): number {
        return this._widthInPixels * this.pixelScale;
    }

    get widthInPixels(): number {
        return this._widthInPixels;
    }

    get height(): number {
        return this._heightInPixels * this.pixelScale;
    }

    get heightInPixels(): number {
        return this._heightInPixels;
    }

    getLocalPosition(left: number, top: number): Vector3 {
        return new Vector3(left * this.pixelScale, top * this.pixelScale, 0)
    }
}